import React from 'react';

import { Backdrop, StackText, SectionLinks, SectionLink } from './Hero.StyledComponents.js';
import { DisplayTiny, Tiny, Regular } from '../../text';
import { SECTIONS } from '../constants';

const StatsPageHero = () => (
  <Backdrop>
    <StackText>
      <DisplayTiny colored="white" align="center">Handshake Statistics</DisplayTiny>
      <SectionLinks>
        <SectionLink colored="white" href={SECTIONS.AUCTIONS.HREF}>
          {SECTIONS.AUCTIONS.TITLE}
        </SectionLink>
        <Regular colored="white">|</Regular>
        <SectionLink colored="white" href={SECTIONS.MARKETPLACE.HREF}>
          {SECTIONS.MARKETPLACE.TITLE}
        </SectionLink>
        <Regular colored="white">|</Regular>
        <SectionLink colored="white" href={SECTIONS.USAGE.HREF}>
          {SECTIONS.USAGE.TITLE}
        </SectionLink>
      </SectionLinks>
      <Tiny colored="dmGrey50" align="center">updated hourly</Tiny>
    </StackText>
  </Backdrop>
);

export { StatsPageHero };