import React from 'react';
import { Footer } from '../footer';

import { StatsPageHero } from './Hero'
import { SECTIONS } from './constants';
import { StatsPageSection, StatsPageSubSection } from './components';
import { StillInterestedCta } from './StillInterestedCta';
import { Top10SalesLists } from './Top10SalesLists';
import { Top10LockupsLists } from './Top10LockupsLists';
import { BidVolume } from './BidVolume';
import { Top9Lockups } from './Top9Lockups';
import { Top10Auctions } from './Top10Auctions';
import { Top10MarketplaceSales } from './Top10MarketplaceSales';
import { MarketplaceVolume } from './MarketplaceVolume';
import { HandshakeUsage } from './HandshakeUsage';
import { debounceWindowEventCallback } from '../../utils/debounceWindowEventCallback';
import { theme } from '../../theme';

const StatsPage = ({ navbarRef, scrolledToTop }) => {
  // scrolls the nar bar along with the page, even though that it has position sticky.
  const translateNavOnScroll = React.useCallback(
    debounceWindowEventCallback(() => {
      if (!navbarRef || !navbarRef.current) throw new Error('Expected navbar ref');

      navbarRef.current.style.transition = '';
      navbarRef.current.style.transform = `translateY(-${window.scrollY}px)`;
      navbarRef.current.firstChild.style.boxShadow = 'none';
    }),
    []
  );

  React.useEffect(() => {
    if (scrolledToTop) {
      navbarRef.current.style.transform = 'translateY(0)';
      navbarRef.current.setAttribute('data-has-reappeared', 'false');
      navbarRef.current.style.transition = 'transform 100ms ease-out';
      window.addEventListener('scroll', translateNavOnScroll);
    }
  }, [navbarRef, scrolledToTop, translateNavOnScroll]);

  return (
    <React.Fragment>
      <div style={{ position: 'sticky', top: 0 }}>
        <StatsPageHero />
      </div>
      <div style={{
        top: 0,
        zIndex: 1,
        position: 'sticky',
        boxShadow: "0px -32px 24px -16px rgba(17, 17, 17, 0.5)"
      }}>
        <StatsPageSection title={SECTIONS.AUCTIONS.TITLE} anchor={SECTIONS.AUCTIONS.ANCHOR}>
          <StatsPageSubSection backgroundColor={theme.color.grey10}>
            <BidVolume />
          </StatsPageSubSection>
          <StatsPageSubSection backgroundColor={theme.color.almostBlack} zeroPaddingBottom>
            <Top9Lockups />
          </StatsPageSubSection>
          <StatsPageSubSection backgroundColor={theme.color.almostBlack}>
            <Top10LockupsLists />
          </StatsPageSubSection>
          <StatsPageSubSection backgroundColor={theme.color.grey10}>
            <Top10Auctions />
          </StatsPageSubSection>
        </StatsPageSection>
        <StatsPageSection title={SECTIONS.MARKETPLACE.TITLE} anchor={SECTIONS.MARKETPLACE.ANCHOR}>
          <StatsPageSubSection backgroundColor={theme.color.grey10}>
            <Top10MarketplaceSales />
          </StatsPageSubSection>
          <StatsPageSubSection backgroundColor={theme.color.almostBlack}>
            <Top10SalesLists />
          </StatsPageSubSection>
          <StatsPageSubSection backgroundColor={theme.color.grey10}>
            <MarketplaceVolume />
          </StatsPageSubSection>
        </StatsPageSection>
        <StatsPageSection title={SECTIONS.USAGE.TITLE} anchor={SECTIONS.USAGE.ANCHOR}>
          <StatsPageSubSection backgroundColor={theme.color.almostBlack}>
            <HandshakeUsage />
          </StatsPageSubSection>
        </StatsPageSection>
        <StillInterestedCta />
        <Footer />
      </div>
    </React.Fragment>
  );
};

export { StatsPage };