import { css } from 'styled-components';
import { theme } from '../../theme';

const SECTIONS = Object.freeze({
  USAGE: {
    get TITLE() { return 'Usage' },
    get ANCHOR() { return this.TITLE.toLowerCase() },
    get HREF() { return `#${this.TITLE.toLowerCase()}` },
  },
  AUCTIONS: {
    get TITLE() { return 'Auctions' },
    get ANCHOR() { return this.TITLE.toLowerCase() },
    get HREF() { return `#${this.TITLE.toLowerCase()}` },
  },
  MARKETPLACE: {
    get TITLE() { return 'Marketplace' },
    get ANCHOR() { return this.TITLE.toLowerCase() },
    get HREF() { return `#${this.TITLE.toLowerCase()}` },
  },
});

const LIST_FIGURE_SPACING_CSS = css`
  max-width: ${({ theme }) => theme.spacing(54)}; /* 432px */

  & > * + * {
    margin-top: ${({ theme }) => theme.spacing(2)};
  }
`;

const LIST_FIGURE_MAX_WIDTH_CSS = css`
  @media ${({ theme }) => theme.breakpoints.sm} {
    margin: 0 auto;
    max-width: ${({ theme }) => theme.spacing(54)}; /* 432px */
  }
`;

const CHART_AXIS_BASE_CONFIG_LIGHT = {
  grid: {
    line: {
      style: {
        stroke: theme.color.grey30,
        lineWidth: 1
      }
    }
  },
  line: {
    style: {
      stroke: theme.color.grey30,
      lineWidth: 1 
    },
  },
  label: {
    offset: 16,
    style: {
      fill: theme.color.brandDark,
      fontSize: 12,
      fontFamily: 'Roboto',
      fontWeight: 500,
    },
  },
  tickLine: {
    style: {
      stroke: theme.color.grey30,
      lineWidth: 1 
    },
    alignTick: true,
  },
}

const CHART_AXIS_BASE_CONFIG_DARK = {
  grid: {
    line: {
      style: {
        stroke: theme.color.dmGrey30,
        lineWidth: 1
      }
    }
  },
  label: {
    offset: 16,
    style: {
      fill: theme.color.white,
      fontSize: 12,
      fontFamily: 'Roboto',
      fontWeight: 500,
    },
  },
  line: {
    style: {
      stroke: theme.color.dmGrey30,
      lineWidth: 1 
    },
  },
  tickLine: {
    style: {
      stroke: theme.color.dmGrey30,
      lineWidth: 1 
    },
    alignTick: true,
  },
}

export {
  SECTIONS,
  LIST_FIGURE_SPACING_CSS,
  LIST_FIGURE_MAX_WIDTH_CSS,
  CHART_AXIS_BASE_CONFIG_DARK,
  CHART_AXIS_BASE_CONFIG_LIGHT
}